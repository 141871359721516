<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <div style="margin-bottom: 16px">
    <a-button :disabled="!hasSelected" type="primary" @click="showAssignLeadsModal = true">
     Toewijzen aan een agent
    </a-button>
    <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `${selectedRowKeys.length} ${selectedRowKeys.length > 1 ? 'items' : 'item'} geselecteerd` }}
        </template>
      </span>
   </div>
   <a-table
    :columns="leadTableColumns"
    :dataSource="leadTableData"
    :loading="loadingLeads"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
 <sdModal
  :onCancel="closeModal"
  :visible="showAssignLeadsModal"
  title="Leads toewijzen"
  type="primary"
 >
  <ModalMain>
   <div class="create-account-form">
    <a-row :gutter="30">
     <a-col
      :xs="24"
     >
      <a-form :model="form" :rules="rules" class="filters" layout="vertical" @finish="handleAssignLeads">
       <a-row :gutter="16">
        <a-col :md="24" :xs="24" class="mb-25">
         <a-form-item label="Organisatie" name="organizationID" style="margin-bottom: 5px">
          <a-select v-model:value="form.organizationID" :allowClear="false" :filter-option="false"
                    :loading="loadingUserFilters" :showSearch="false" class="sDash_fullwidth-select"
                    mode="single" size="large" @change="fetchUsers">
           <a-select-option v-for="(item,index) in orderOrganization" :key="index" :value="item.key"
                            name="organizationID">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="24" :xs="24" class="mb-25">
         <a-form-item label="Gebruiker" name="assignedUserID" style="margin-bottom: 5px">
          <a-select v-model:value="form.assignedUserID" :allowClear="true" :disabled="!form.organizationID"
                    :filter-option="false"
                    :showSearch="false"
                    class="sDash_fullwidth-select" mode="single"
                    size="large">
           <a-select-option v-for="(item,index) in userFilters" :key="index" :value="item.key"
                            name="assignedUserID">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-col :md="24" :xs="24" class="mb-25">
        <a-button :loading="loadingAssign" class="btn-signin" html-type="submit" size="large" type="primary"> Toewijzen
        </a-button>
       </a-col>
      </a-form>
     </a-col>
    </a-row>
   </div>
  </ModalMain>
 </sdModal>
</template>
<script>
import {TableStyleWrapper, TableWrapper} from '../../styled';
import {computed, defineComponent, reactive, ref} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {getLeadStatus} from "../../../utility/utility";
import VueTypes from "vue-types";

const showAssignLeadsModal = ref(false);

const leadTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'relationName',
  key: 'relationName',
 },
 {
  title: 'Gebied',
  dataIndex: 'area',
  key: 'area',
 },
 {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
 },
 {
  title: 'Datum & Tijd',
  dataIndex: 'date',
  key: 'date',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const LeadsListTable = defineComponent({
 name: 'LeadsListTable',
 components: {TableStyleWrapper, TableWrapper},
 props: {
  ids: VueTypes.array.def([]),
 },
 setup() {
  // const router = useRouter();
  const {state, dispatch} = useStore();
  // const {matched} = useRoute();
  // const {path} = matched[1];
  const leads = computed(() => state.lead.leads);
  const loadingLeads = computed(() => state.lead.loadingLeads);
  const loadingAssign = computed(() => state.lead.loadingAssign);
  const loadingUserFilters = computed(() => state.filter.loadingUserFilters);
  const currentPage = computed(() => state.lead.currentPage);
  const count = computed(() => state.lead.count);
  const userFilters = computed(() => state.filter.userFilters);
  // const {can} = useAbility();
  // const handleDelete = async (id) => {
  //  await dispatch('deleteSender', id);
  //  await dispatch('getSenders', {page: state.lead.currentPage, pageSize: state.lead.pageSize});
  // };
  // const handleEdit = (id) => {
  //  router.push(`${path}/${id}`);
  // };
  const leadTableData = computed(() =>
   leads.value.map((lead) => {
    const {id, relationName, leadAreaTimeBlock, date, status, leadArea} = lead;

    return {
     key: id,
     id: id,
     area: <span>{leadArea.name} <br/><small>{leadArea.postalCodes}</small></span>,
     relationName: <span>{relationName}</span>,
     status: <span>{getLeadStatus(status)}</span>,
     date: <span>{leadAreaTimeBlock?.timeFrom}-{leadAreaTimeBlock?.timeTill} {moment(date).format('DD-MM-YYYY')}</span>,
     // action: (
     //  <div className="table-actions">
     //   {can('view', 'sender') ? (
     //    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
     //     <sdFeatherIcons type="edit" size="16"/>
     //    </sdButton>
     //   ) : ('')}
     //   {can('delete', 'sender') ? (
     //    <a-popconfirm
     //     title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
     //     ok-text="Ja"
     //     cancel-text="Nee"
     //     placement="leftTop"
     //     onConfirm={() => handleDelete(id)}
     //    >
     //     <sdButton className="btn-icon" type="default" to="#" shape="circle">
     //      <sdFeatherIcons type="trash-2" size="16"/>
     //     </sdButton>
     //    </a-popconfirm>
     //   ) : ('')}
     //  </div>
     // ),
    };
   }),
  );

  const selectedRowKeys = ref([]);
  const hasSelected = computed(() => selectedRowKeys.value.length > 0);
  const onSelectChange = (keys) => {
   console.log('selectedRowKeys changed: ', keys);
   selectedRowKeys.value = keys;
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageLeads', event.current);
   await dispatch('getLeads', {page: event.current, pageSize: state.sender.pageSize});
  }

  const closeModal = () => {
   form.organizationID = '';
   form.assignedUserID = '';
   form.ids = [];
   showAssignLeadsModal.value = false;
  }
  const handleAssignLeads = async () => {
   const keys = [];
   for (let i = 0; i < selectedRowKeys.value.length; i++) {
    keys.push(leads.value[selectedRowKeys.value[i] - 1].id)
   }

   form.ids = keys;

   await dispatch('assignLeads', {values: form, close: closeModal});
  }

  const fetchUsers = async (event) => {
   form.assignedUserID = '';
   await dispatch('getUserFilters', {organizationID: event, pageSize: 200});
  }

  const orderOrganization = computed(() => state.filter.orderOrganization);

  const form = reactive({
   organizationID: '',
   assignedUserID: '',
   ids: [],
  });

  const rules = {
   organizationID: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   assignedUserID: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  return {
   fetchUsers,
   rules,
   form,
   leadTableColumns,
   leadTableData,
   onSelectChange,
   loadingLeads,
   paginationChange,
   count,
   currentPage,
   selectedRowKeys,
   hasSelected,
   showAssignLeadsModal,
   handleAssignLeads,
   orderOrganization,
   loadingUserFilters,
   userFilters,
   loadingAssign,
   closeModal,
  };
 },
});

export default LeadsListTable;
</script>
