<template>
 <CardToolbox>
  <sdPageHeader title="Leads">
   <template #subTitle>
    <span class="title-counter">{{ count }} lead{{ count > 0 && count < 2 ? '' : 's' }}</span>
    <AutoCompleteStyled placeholder="Zoeken op naam..." style="width:100%">
     <a-input v-model:value="search" type="input">
      <template #suffix>
       <sdFeatherIcons type="search"/>
      </template>
     </a-input>
    </AutoCompleteStyled>
   </template>
   <!--   <template #buttons>-->
   <!--    <SenderAdd v-if="$can('create', 'sender')"/>-->
   <!--   </template>-->
  </sdPageHeader>
 </CardToolbox>

 <Main>
  <a-row :gutter="15">
   <a-col :md="24">
    <LeadsListTable/>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {AutoCompleteStyled, CardToolbox, Main} from '../styled';
import locale from "@/static/locale/nl.json";
import LeadsListTable from "./component/LeadsTable.vue";

const LeadList = defineComponent({
 name: 'LeadList',
 components: {Main, CardToolbox, LeadsListTable, AutoCompleteStyled},
 setup() {
  const {state, dispatch} = useStore();
  const searchData = computed(() => state.headerSearchData);
  const count = computed(() => state.lead.count);
  const selectedRowKeys = ref(0);
  const selectedRows = ref(0);
  const search = ref(null);
  let debounce = null;

  watch(search, (query) => {
   clearTimeout(debounce)
   debounce = setTimeout(async () => {
    const searchValues = {
     q: query,
     pageSize: state.lead.pageSize,
     page: 1,
    };
    await dispatch('setCurrentPageLeads', 1);
    await dispatch('getLeads', searchValues);
   }, 600);
  });

  onMounted(() => {
   window.scrollTo(0, 0);
   dispatch('getOrderOrganizationFilters');
   dispatch('getLeads', {page: state.lead.currentPage, pageSize: state.lead.pageSize});
  });

  return {
   searchData,
   selectedRowKeys,
   selectedRows,
   count,
   locale,
   search,
  };
 },
});

export default LeadList;
</script>
